import React, { useState, useRef } from 'react';
import { Storage } from 'aws-amplify';
import xLogoImage from './img/X-logo.png';
import './AudioRecorder.css';
const AudioRecorder = () => {
  const [isRecording, setIsRecording] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [audioUrl, setAudioUrl] = useState('');
  const [recording, setRecording] = useState(null);
  const mediaRecorderRef = useRef(null);
  const [isUploaded, setIsUploaded] = useState(false);

  const generateRandomFileName = (extension = 'wav') => {
    const timestamp = Date.now();
    const randomNum = Math.floor(Math.random() * 10000);
    return `file_${timestamp}_${randomNum}.${extension}`;
  };

  const startRecording = async () => {
    setIsRecording(true);
    setIsUploaded(false)
    setAudioUrl('');
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    const mediaRecorder = new MediaRecorder(stream);

    mediaRecorderRef.current = mediaRecorder;

    const chunks = [];
    mediaRecorder.ondataavailable = (event) => {
      chunks.push(event.data);
    };

    mediaRecorder.onstop = () => {
      const blob = new Blob(chunks, { type: 'audio/wav' });
      const url = URL.createObjectURL(blob);
      setAudioUrl(url);
      setRecording(blob);
    };

    mediaRecorder.start();
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
    }
  };

  const uploadAudio = async () => {
    if (!recording) return;
    setIsUploading(true)
    try {
      const file = new File([recording], generateRandomFileName(), { type: 'audio/wav' });
      const result = await Storage.put(file.name, file, {
        contentType: file.type,
        level: 'public' // Use 'public' level for unauthenticated access
      });
      console.log('Audio uploaded successfully:', result);
      setIsUploaded(true); // Set isUploaded to true after uploading
    } catch (error) {
      console.error('Error uploading audio:', error);
    }
    setIsUploading(false)
  };

  return (
    <div className="record-container">
      <div className="record-content">
        <div className="record-section">
          <p>Share your feedback about GFF:</p>
          <button
            className={`record-btn ${isRecording ? 'recording' : ''}`}
            onClick={isRecording ? stopRecording : startRecording}
          >
            {isRecording ? 'Stop Recording' : 'Start Recording'}
          </button>
        </div>
        <div className="playback-section">
          {!isUploaded && recording && audioUrl && (
            <div className="audio-controls">
              <audio controls src={audioUrl} className="audio-player" />
              {
                isUploading ? <b>Uploading...</b> :
                <button className="upload-btn" onClick={uploadAudio}>
                  Upload
                </button>
              }
            </div>
          )}
          {isUploaded && <p className="success-message">Successfully uploaded</p>}
        </div>
      </div>
      <div className="tweet-section">
        <p>Post your tweet with <span className="gradient-text">#GFF</span> hashtag</p>
        <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
          <img src={xLogoImage} alt="X-logo" className="h-6" />
        </a>
      </div>
    </div>
  );
};
export default AudioRecorder;

