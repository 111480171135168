import React, { useState, useCallback, useEffect } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import amazonLogoImage from './img/amazon-logo.png';
import perceptLogoImage from './img/percept-logo.png';
import AudioRecorder from './AudioRecorder';
import WordCloud from 'react-wordcloud';
import WebSocketStatus from './WebsocketStatus';
import { Bar, Radar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend } from 'chart.js';
import * as PropTypes from "prop-types";

ChartJS.register(RadialLinearScale, PointElement, LineElement, CategoryScale, LinearScale, BarElement,Filler, Tooltip, Legend);


const API_GATEWAY_URL = 'https://fp16lel8ng.execute-api.us-west-2.amazonaws.com/'
const suggestionsColorSchema = {
    '1': 'bg-purple-500 bg-opacity-10',    // #8000ff
    '2': 'bg-indigo-500 bg-opacity-20', // #0000ff
    '3': 'bg-blue-500 bg-opacity-30', // #0080ff
    '4': 'bg-cyan-500 bg-opacity-40',   // #00ffff
    '5': 'bg-teal-500 bg-opacity-50',  // #00ff80
    '6': 'bg-green-500 bg-opacity-60',   // #00ff00
    '7': 'bg-lime-500 bg-opacity-70',   // #80ff00
    '8': 'bg-yellow-500 bg-opacity-80',   // #ffff00
    '9': 'bg-orange-500 bg-opacity-90', // #ff8000
    '10': 'bg-red-500 bg-opacity-100' // #ff0000
}
const socketColorSchema = {
    [ReadyState.OPEN]: 'bg-green-500',
    [ReadyState.CLOSED]: 'bg-red-500',
    [ReadyState.CLOSING]: 'bg-orange-500',
    [ReadyState.CONNECTING]: 'bg-yellow-500',
    [ReadyState.UNINSTANTIATED]: 'bg-black'
}
function LiveFeed(props) {
    return <div className="bg-white rounded-xl border border-blue-900">
        <p className="text-xl text-center bg-blue-100 rounded-t-xl p-2 mb-4">Live
            Feed</p>
        <div className="h-80 overflow-hidden pb-4 pl-4">
            <ul className="h-full overflow-auto">
                {props.messageHistory.map((message) => (
                    <li className="border-b border-gray-300 py-3 flex items-center gap-2">
                        <p className="text-left flex-1">{message.transcript}</p>
                        <p className="w-10 flex items-center justify-center">
                            {message.source === 'audio' ?
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                     fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                     stroke-linejoin="round" className="size-6 stroke-green-700">
                                    <polygon points="11 5 6 9 2 9 2 15 6 15 11 19 11 5"></polygon>
                                    <path d="M19.07 4.93a10 10 0 0 1 0 14.14M15.54 8.46a5 5 0 0 1 0 7.07"></path>
                                </svg> :
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                     viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                     stroke-width="2"
                                     stroke-linecap="round" stroke-linejoin="round"
                                     className="size-6 stroke-blue-600">
                                    <path
                                        d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z"></path>
                                </svg>}
                        </p>
                    </li>
                ))}
            </ul>
        </div>
    </div>;
}

LiveFeed.propTypes = {
    messageHistory: PropTypes.arrayOf(PropTypes.any),
};

function VoiceOfVisitor(props) {
    return (
        <>
            <div className="bg-white rounded-xl border border-blue-900">
                <p className="text-xl text-center bg-blue-100 rounded-t-xl p-2 mb-4">Voice of the visitor</p>
                <div className="h-80">
                    <WordCloud words={props.words} options={props.options}/>
                </div>
            </div>
        </>
);
}
VoiceOfVisitor.propTypes = {
    words: PropTypes.arrayOf(PropTypes.any),
    options: PropTypes.shape({
        rotationAngles: PropTypes.arrayOf(PropTypes.number),
        fontSizes: PropTypes.arrayOf(PropTypes.number),
        rotations: PropTypes.number
    })
};
export const AnecdotesFeed = () => {
    const [socketUrl, setSocketUrl] = useState('wss://jrxpq8qgik.execute-api.us-west-2.amazonaws.com/production');
    const [messageHistory, setMessageHistory] = useState([]);
    const [engagementSummary, setEngagementSummary] = useState([]);
    const [wordCloud, setWordCloud] = useState([]);
    const [radarData, setRadarData] = useState({})
    const [suggestions, setSuggestions] = useState([]);
    const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl);
    function updateWordcloud(wordcloud, keywords) {
      //Sorry for the double for loop
      const wordsToAdd = [];
      let match;
      for (let keyword of keywords) {
        match = false
        for (let word of wordcloud) {
          if (word.text == keyword) {
            match = true
            word.value += 1
          }
        }
        if (!match) {
          wordsToAdd.push({text: keyword, value: 1})
        }
      }
      return [...wordcloud, ...wordsToAdd]
    }
    function transformData(data) {
      // Extract unique labels and datasets
      const labels = [...new Set(data.map(item => item.category))];
      const datasetNames = [...new Set(data.map(item => item.sentiment))];

      const labelsColor = {
          'Negative': 'rgb(239,84,84, 0.6)',
          'Positive': 'rgb(118, 241, 163, 0.6 )',
          'Neutral': 'rgb(239,239,62, 0.5)',
      }
    
      // Create the datasets array in the required format
      const transformedDatasets = datasetNames.map((datasetName, index) => {
        const datasetValues = labels.map(label => {
          const foundItem = data.find(item => item.category === label && item.sentiment === datasetName);
          return foundItem ? foundItem.sum : 0; // Default to 0 if no value is found
        });
    
        // Generate random colors for each dataset
        const backgroundColor = labelsColor[datasetName];
        console.log(backgroundColor)
        const borderColor = backgroundColor
        const pointBackgroundColor = borderColor;
    
        return {
          label: datasetName,
          data: datasetValues,
          fill: true,
          backgroundColor,
          borderColor,
          pointBackgroundColor,
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: borderColor,
        };
      });
    
      return {
        labels,
        datasets: transformedDatasets,
      };
    };
    function updateRadarChart(radarData, analysis) {
      const axiss = Object.keys(analysis)
      for (let axis of axiss) {
        for (let sentiment of Object.keys(analysis[axis])){
          let value = analysis[axis][sentiment]
          if (value > 0) {
            const index = radarData.labels.indexOf(axis)
            try {
              radarData.datasets.find((dataset) => dataset.label === sentiment).data[index] += value
            } catch (e) {
              console.log(e)
            }
          }
        }
      }
      return radarData
    }

    useEffect(() => {
      const getEngagementSummary = async () => {
        try {
          const response = await fetch(API_GATEWAY_URL + 'engagement');
          const engagement = await response.json()
          setEngagementSummary(engagement)
        } catch (e) {
          console.log(e)
        }
      }
      const getFeedMessages = async () => {
        try{
          const response = await fetch(API_GATEWAY_URL + 'feed');
          const data = await response.json()
          const cachedFeedMessages = data.messages
          const decodedFeedMessages = cachedFeedMessages.map((element) => {
            return JSON.parse(element)
          })
          setMessageHistory([...messageHistory, ...decodedFeedMessages])
        } catch (e) {
          console.log(e)
        } 
      }
      const getWordCloud = async () => {
        try{
          const response = await fetch(API_GATEWAY_URL + 'wordcloud');
          const data = await response.json()
          setWordCloud(data)
        } catch (e) {
          console.log(e)
        } 
      }
      const getAnalysis = async () => {
        try {
          const response = await fetch(API_GATEWAY_URL + 'analysis');
          const data = await response.json()
          setRadarData(transformData(data))
        } catch (e) {
          console.log(e)
        }
      }
      const getSuggestions = async () => {
        try {
          const response = await fetch(API_GATEWAY_URL + 'suggestions');
          const data = await response.json()
          const suggestions = JSON.parse(data.suggestions).sort((a,b) => b.severity - a.severity);
          setSuggestions(suggestions);
        } catch (e) {
          console.log(e)
        }
      }
      getEngagementSummary()
      getFeedMessages()
      getWordCloud()
      getAnalysis()
      getSuggestions()
    }, [])

    useEffect(() => {
        if (lastMessage !== null) {
          const eventMessage = JSON.parse(lastMessage.data);
          if (eventMessage.type === "feed") {
            const feedMessage = eventMessage.data;
            console.log(feedMessage)
            setMessageHistory([feedMessage, ...messageHistory])
          } else if (eventMessage.type === "result") {
            const resultMessage = eventMessage.data
            const resultObject = JSON.parse(resultMessage.transcript)
            setMessageHistory(messageHistory.map((mess) =>  {
              if (mess.id === resultMessage.id) {
                mess.summary = resultObject['Executive Summary']
              }
              return mess
            }))
            setRadarData(updateRadarChart(radarData, resultObject['Sentiment Analysis'])) 
            setWordCloud(updateWordcloud(wordCloud, resultObject.Keywords))
          } else if (eventMessage.type === "suggestions") {
            const liveSuggestions = eventMessage.data;
            setSuggestions([])
            setTimeout(function(){
              setSuggestions(liveSuggestions.suggestions.sort((a,b) => b.severity - a.severity))
            }, 1500);
          }
        }
      }, [lastMessage]);

    const connectionStatus = {
        [ReadyState.CONNECTING]: 'Connecting',
        [ReadyState.OPEN]: 'Open',
        [ReadyState.CLOSING]: 'Closing',
        [ReadyState.CLOSED]: 'Closed',
        [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
      }[readyState];

      const timeLabels = engagementSummary.map(item => item.hour.substr(0, item.hour.lastIndexOf(":")));
      const timeCounts = engagementSummary.map(item => item.count);

      const timeData = {
        labels: timeLabels,
        datasets: [
          {
            label: 'Engagement',
            data: timeCounts,
            backgroundColor: 'rgba(54, 162, 235, 0.5)',
            borderColor: 'rgba(54, 162, 235, 1)',
            borderWidth: 1
          }
        ]
      };

      const timeOptions = {
        scales: {
          x: {
            title: {
              display: true,
              text: 'Time'
            },
            ticks: {
              maxRotation: 45,
              minRotation: 45
            }
          },
          y: {
            title: {
              display: true,
              text: 'Interactions'
            },
            beginAtZero: true
          }
        }
      };

      const wordCloudOptions = {
        rotations: 5,
        rotationAngles: [0, 15, -45],
        fontSizes: [40, 50, 70],
      };

      const radarOptions = {
        scales: {
          r: {
            angleLines: {
              display: true,
            },
            suggestedMin: 0,
            suggestedMax: 100,
          },
        },
      };
      return (
          <div>
            <header className="flex justify-between items-center px-4">
            <div className="flex-grow w-20">
              <img src={amazonLogoImage} alt="Logo" className="h-6" />
            </div>
            <div className="flex flex-col items-center">
              <img src={perceptLogoImage} alt="Logo" className="h-9" />
              <p className="text-xs text-gray-500">powered by AWS Bedrock</p>
            </div>
            <div className="flex-grow w-20 flex justify-end">
              <WebSocketStatus readyState={readyState} socketColorSchema={socketColorSchema} />
            </div>
          </header>
              <hr></hr>
                <AudioRecorder />
              <div className="grid grid-cols-12 grid-rows-auto lg:grid-rows-2 gap-4 ml-2 mr-2">
                  <div className="order-1 col-span-12 lg:order-1 lg:col-span-8 xl:order-1 xl:col-span-6 xl:row-span-1">
                      <LiveFeed messageHistory={messageHistory}/>
                  </div>

                  <div className="order-3 col-span-12 lg:order-2 lg:col-span-8 xl:order-3 xl:col-span-6 xl:row-span-1">
                      <div className="bg-white rounded-xl border border-blue-900">
                          <p className="text-xl text-center bg-blue-100 rounded-t-xl p-2 mb-4">Engagement</p>
                          <div className="h-80 flex justify-center items-center">
                              <Bar data={timeData} options={timeOptions}/>
                          </div>
                      </div>
                  </div>

                  <div className="order-4 col-span-12 lg:order-4 lg:col-span-4 xl:order-4 xl:col-span-3">
                      <div className="bg-white rounded-xl border border-blue-900">
                          <p className="text-xl text-center bg-blue-100 rounded-t-xl p-2 mb-4">Sentiment</p>
                          <div className="h-80 flex items-center justify-center">
                              {
                                  radarData.labels ? <Radar data={radarData} options={radarOptions}/> : <b>Loading...</b>
                              }
                          </div>
                      </div>
                  </div>

                  <div className="order-5 col-span-12 lg:order-4 lg:col-span-4 xl:order-2 xl:col-span-3">
                      <VoiceOfVisitor words={wordCloud} options={wordCloudOptions}/>
                  </div>

                  <div className="order-2 col-span-12 lg:order-1 lg:col-span-4 lg:row-span-3 xl:order-2 xl:col-span-3 xl:row-span-2">
                      <div className="bg-white rounded-xl border border-blue-900">
                          <p className="text-xl text-center bg-blue-100 rounded-t-xl p-2">Key Takeaways</p>
                          <div className="h-[42.5rem] overflow-hidden">
                              {
                                suggestions.length > 0 ?
                                ( 
                                <ul className="h-full overflow-auto">
                                  {suggestions.map((suggestion, idx) => (
                                      <li className="flex gap-2 items-center text-left ml-4 border-b border-b-gray-200">
                                            <span
                                                className={`size-8 rounded-full ${suggestionsColorSchema[suggestion.severity]}`}></span>
                                          <span className="flex-1 p-2 rounded-md">{suggestion.suggestion}</span>
                                      </li>
                                  ))}
                                </ul>
                                ) : (
                                  <div className='justify-center items-center h-full'>
                                    Loading Takeaways...
                                  </div>
                                )
                              }
                          </div>
                          <div className="px-4 pt-2">
                              <div className="h-6 bg-gradient-heatmap">
                              </div>
                              <div className="flex items-center justify-between pt-1">
                                  <p className="text-xs">
                                      More severe
                                  </p>
                                  <p className="text-xs">
                                      Less severe
                                  </p>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      );
}